import { Dialog, Transition, DialogBackdrop } from "@headlessui/react";
import { Fragment } from "react";
import CloseIcon from "public/close.svg";
import { Theme } from "@radix-ui/themes";
import clsx from "clsx";
import { cn } from "@/utils";

export const DIALOG_CLOSE_TYPES = {
  CLOSE_ICON: "close_icon",
  ACTIONS: "actions",
  CLOSE_OUTSIDE: "close_outside",
  DEFAULT: "default",
};

/**
 * Dialog is a component that displays a dialog.
 * It can be triggered to open and close programmatically or by clicking on a trigger element.
 *
 * @param {Object} props - The properties for configuring the OfferWallDialog component.
 * @param {string} props.title - The title of the dialog.
 * @param {ReactNode} props.children - The content of the dialog, which can be any valid React node.
 * @param {boolean} [props.closable=false] - Determines whether the dialog can be closed by the user (using close button).
 * @param {boolean} [props.isOpen=false] - Determines whether the dialog is open by default.
 * @param {boolean} [props.noOutsideClickClose=false] - If set to true, clicking outside the dialog won't close it.
 * @param {string} [props.className=''] - Additional CSS class to apply to the dialog container.
 * @param {string} [props.contentClassName=''] - Additional CSS class to apply to the content section of the dialog.
 * @param {function({ close: function })|null|undefined} [props.actions] - A function that returns JSX representing actions or buttons inside the dialog.
 * @param {function({ open: function })|null|undefined} [props.trigger] - A function that returns JSX representing the trigger element to open the dialog.
 * @param {function(boolean, string)} [props.onOpenChange] - A callback function invoked when the dialog's open state changes.
 * @param {boolean} open - The current open state of the dialog.
 * @param {string} type - A string indicating the type of open state change ('open' or 'close').
 * @param {Object} classes - A classes Object
 *
 * @returns {JSX.Element} The JSX representation of the OfferWallDialog component.
 */

export default function HDialog({
  title,
  children,
  closable = false,
  isOpen = false,
  noOutsideClickClose = false,
  className = "",
  contentClassName = "",
  actions,
  trigger,
  onOpenChange = (open, type) => {},
  position = "center",
  classes = {},
}) {
  function closeModal(type) {
    onOpenChange(false, type);
  }

  function closeByAction() {
    closeModal(DIALOG_CLOSE_TYPES.ACTIONS);
  }

  function openModal() {
    onOpenChange(true);
  }

  return (
    <>
      {/* sample dialog trigger */}
      {/* <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="px-4 py-2 text-sm font-medium text-white  rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div> */}
      {!!trigger && trigger({ open: openModal })}

      <Transition
        appear
        id={title}
        key={title}
        show={Boolean(isOpen)}
        as={Fragment}
        className="shadow-2xl"
      >
        <Dialog
          as="div"
          className="relative z-50"
          onClose={(value) => {
            if (noOutsideClickClose) return;
            closeModal(DIALOG_CLOSE_TYPES.DEFAULT);
          }}
          id={title}
          key={title}
        >
          <Theme
            accentColor="red"
            grayColor="mauve"
            panelBackground="solid"
            radius="small"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed top-0 bottom-0 left-0 right-0 /75" />
            </Transition.Child>

            <div
              className={clsx(
                "fixed  bottom-0 left-0 right-0 overflow-y-auto",
                position === "bottom" ? "" : "top-0"
              )}
            >
              <div
                className={clsx(
                  "flex items-center justify-center min-h-full p-6 text-center",
                  classes.wrapper
                )}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={cn(
                      "flex flex-col max-h-[90vh] w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-violet-50 rounded-lg border border-white/40 ",
                      className
                    )}
                  >
                    {!!title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-dimmed"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {/* close button on right */}
                    {!!closable && (
                      <button
                        className={cn(
                          "absolute text-lg right-4 top-4 ring-0 outline-0 text-slate-400",
                          classes.closeBtn
                        )}
                        onClick={() => {
                          closeModal(DIALOG_CLOSE_TYPES.CLOSE_ICON);
                        }}
                        aria-label="close"
                      >
                        <CloseIcon className="text-2xl text-dimmed" />
                      </button>
                    )}
                    <div className={cn("overflow-auto", contentClassName)}>
                      {children}
                    </div>

                    {!!actions && (
                      <div className="mt-4">
                        {actions({ close: closeByAction })}
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Theme>
          <Dialog.Backdrop className="fixed inset-0 bg-black/30" />
        </Dialog>
      </Transition>
    </>
  );
}
